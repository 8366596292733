<template>
  <div>
    <header-page :title="'Users'" :hasBackLink="false" />

    <div class="filters-container">
      <div class="filters-block">
        <div class="search">
          <input-text v-model="search_text" />
          <div
            v-if="isShowClear"
            class="main-button lg ml-15"
            @click="clearFilters"
          >
            <div>Clear</div>
          </div>
        </div>
        <div class="button">
          <div v-if="false" class="main-button lg orange" @click="setFilters">
            <div class="icon" v-html="Filter"></div>
            <div>Filters</div>
          </div>
        </div>
      </div>
      <div class="selected-filters" v-if="selectedFilters.length">
        <div v-for="item in selectedFilters" :key="item.text">
          <div class="icon" @click="removeFilter(item.id)" v-html="Cross"></div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      :listTabs="listTabs"
      @setTab="setTab"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewUser"
      @saveRow="saveUser"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
    />
    <set-filters v-if="isVisibleFilters" v-model="isVisibleFilters" />
    <alert-yes-no
      v-if="isVisibleConfirmSendInvite"
      title="Confirm"
      text=""
      :alertResult="alertConfirmSendInvite"
    />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import NewRowUser from '@/components/NewRows/NewRowUser'
import { fetchData, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'Users',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isSelectable: false,
        isEditable: hasAccess(permissions.update_user),
        isAdd: hasAccess(permissions.create_user),
        isDelete: hasAccess(permissions.delete_user),
        addButtonText: 'Add user',
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">Are you sure you want to deactivate user <b>&nbsp;${rows[0].email}</b>?</div>`
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'email',
          text: 'Email Address',
          cssClass: 'bold-underline',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'first_name',
          text: 'First name',
          width: '180px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'last_name',
          text: 'Last name',
          width: '180px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'role',
          text: 'Role',
          width: '180px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'reported_to',
          text: 'Reported to',
          width: '200px',
        }),
      ],
      newUser: {
        email: null,
        password: null,
        role: null,
        first_name: null,
        last_name: null,
        reported_to: null,
      },
      newRowComponent: shallowRef(NewRowUser),
      isVisibleConfirmSendInvite: false,
      idInviteEntity: 0,

      listTabs: [
        {
          id: 'active',
          name: 'Active',
          count: 0,
          isActive: true,
          isVisible: true,
        },
        {
          id: 'not-active',
          name: 'Not active',
          count: 0,
          isActive: false,
          isVisible: true,
        },
      ],
      activeTab: 'active',
    }
  },

  watch: {
    activeTab() {
      this.setOptionalColumnsAndGrig()
    },
  },

  mounted() {
    document.addEventListener('click', this.showAlertConfirmSendInvite)
    document.addEventListener('click', this.activateUser)
  },

  beforeUnmount() {
    document.removeEventListener('click', this.showAlertConfirmSendInvite)
    document.removeEventListener('click', this.activateUser)
  },

  created() {
    this.setOptionalColumnsAndGrig()
    this.setDataFromQueryParams() // 3
    this.loadCountTabs()
    this.fetchData()
  },

  methods: {
    setOptionalColumnsAndGrig() {
      let colsKeyUrlIndex = this.columns.length + 2
      if (this.activeTab === 'active') {
        this.options.isEditable = hasAccess(permissions.update_user)
        this.options.isAdd = hasAccess(permissions.create_user)
        this.options.isDelete = hasAccess(permissions.delete_user)

        let idx = this.columns.findIndex((column) => column.key === 'activate')
        if (idx > -1) this.columns.splice(idx, 1)

        this.columns.push(
          new GridColumn({
            keyUrl: 's' + colsKeyUrlIndex++,
            key: 'invite_status',
            text: 'Invite status',
            positionDefault: 14,
            formatedValue: ({ row }) => {
              if (row?.is_active)
                return `<div class="underline hand js-send-invite" data-id="${row.id}">Send invite</div>`
              return ''
            },
            width: '150px',
          })
        )
      } else if (this.activeTab === 'not-active') {
        this.options.isEditable = false
        this.options.isAdd = false
        this.options.isDelete = false

        let idx = this.columns.findIndex(
          (column) => column.key === 'invite_status'
        )
        if (idx > -1) this.columns.splice(idx, 1)

        this.columns.push(
          new GridColumn({
            keyUrl: 's' + colsKeyUrlIndex++,
            key: 'activate',
            text: 'Activate',
            positionDefault: 14,
            formatedValue: ({ row }) => {
              if (!row?.is_active)
                return `<div class="underline hand js-activate-entity" data-id="${row.id}">Activate</div>`
              return ''
            },
            width: '150px',
          })
        )
      }
    },

    async loadCountTabs() {
      let query = `page=1&per_page=1`

      let usersActive = await fetchData(
        urls.users,
        'GET',
        null,
        query + `&is_active=true`
      )
      let usersActiveTab = this.listTabs.find((item) => item.id === 'active')
      usersActiveTab.count = usersActive?.count || 0

      let usersNotActive = await fetchData(
        urls.users,
        'GET',
        null,
        query + `&is_active=false`
      )
      let usersNotActiveTab = this.listTabs.find(
        (item) => item.id === 'not-active'
      )
      usersNotActiveTab.count = usersNotActive?.count || 0
    },

    setTab(id) {
      if (!id) return

      this.activeTab = id
      this.activateTab(id)
    },

    async activateUser(e) {
      if (!e.target.classList.contains('js-activate-entity')) return
      let id = e.target.getAttribute('data-id')

      const user = { id: Number(id), is_active: true }
      await this.editRow(user)
      this.fetchData()
      this.loadCountTabs()
    },

    showAlertConfirmSendInvite(e) {
      if (!e.target.classList.contains('js-send-invite')) return
      this.idInviteEntity = e.target.getAttribute('data-id')
      this.isVisibleConfirmSendInvite = true
    },

    async alertConfirmSendInvite(result) {
      if (result) await this.sendInvite()

      this.isVisibleConfirmSendInvite = false
      this.idInviteEntity = 0
    },

    async sendInvite() {
      let result = await fetchData(
        urls.usersSendInvite + `/${this.idInviteEntity}`,
        'GET'
      )

      this.$notify({
        text: result,
        type: 'success',
        duration: 10000,
      })
    },

    saveNewUser(user) {
      this.setNewUser(user)
      this.addRow()
    },

    saveUser(user) {
      this.editRow(user)
    },

    newUserToDefault() {
      for (let key in this.newUser) {
        this.newUser[key] = null
      }
    },

    setNewUser(user) {
      for (let key in user) {
        this.newUser[key] = user[key]
      }
    },

    async editRow(user) {
      this.$store.commit('setIsLoading', true)
      const id = user.id
      delete user.id

      let result = await fetchData(
        urls.users + `/${id}`,
        'POST',
        this.bodyToSave(user)
      )
      if (!result?.detail) {
        let userRow = this.rows.find((item) => item.id === id)
        if (userRow) {
          for (const key in user) {
            if (key in userRow) userRow[key] = user[key]
          }
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.userCreate,
        'POST',
        this.bodyToSave(this.newUser)
      )
      if (!result?.detail) {
        const tmpUser = this.newUser
        this.rows.unshift({ ...{ id: result.id }, ...tmpUser })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newUserToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(id) {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(urls.users + `/${id}`, 'DELETE')
      if (!result?.detail) {
        const idx = this.rows.findIndex((item) => item.id === id)
        this.rows.splice(idx, 1)
      } else console.error(result.detail)
      this.loadCountTabs()
      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += `&${this.queryToFilters()}&${this.queryToSort()}`

      let result = await fetchData(urls.users, 'GET', null, query)
      this.rows = result?.rows || []

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },

    queryToFilters() {
      //let query = ''
      let searchParams = new URLSearchParams()

      switch (this.activeTab) {
        case 'active':
          searchParams.append('is_active', 'true')
          //query += `&is_active=true`
          break

        case 'not-active':
          searchParams.append('is_active', 'false')
          // query += `&is_active=false`
          break

        //default:
        //query += ``
      }

      //return query
      return searchParams.toString()
    },

    setFilters() {
      this.isVisibleFilters = !this.isVisibleFilters
    },
  },
}
</script>
