<template>
    <tr class="add-row">
        <td>
            <div class="add-row-buttons">
                <div class="main-button orange no-background sm" @click="save">Save</div>
                <div class="main-button no-background sm" @click="cancel">Cancel</div>
            </div>
        </td>
        <td>
            <div class="table-cell" :style="setWidthColumn(getColumn('email'))">
                <input-text v-model="email"/>
            </div>
        </td>
        <td>
            <div class="table-cell" :style="setWidthColumn(getColumn('first_name'))">
                <input-text v-model="first_name"/>
            </div>
        </td>
        <td>
            <div class="table-cell" :style="setWidthColumn(getColumn('last_name'))">
                <input-text v-model="last_name"/>
            </div>
        </td>
        <td>
            <div class="table-cell" :style="setWidthColumn(getColumn('role'))">
                <input-select v-model="role"/>
            </div>
        </td>
        <td>
            <div class="table-cell" :style="setWidthColumn(getColumn('reported_to'))">
                <input-select v-model="reported_to"/>
            </div>
        </td>
        <td>
            <div class="table-cell" :style="setWidthColumn(getColumn('invite_status'))">
         
            </div>
        </td>
    </tr>
</template>

<script>
import InputSelect from '@/components/inputs/InputSelect'
import { fetchData, urls} from '@/utils/urls.js'
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
    name: 'NewRowUser',
    mixins: [newRowMixin],
    components:{ InputSelect },

    data() {
        return {
            email: {
                value: '',
                isValid: false,
                clearable: true,
                required: true,
                readonly: false,
                regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                errorKey: '',
                class: 'sm'
            },            
            first_name: {
                value: '',
                isValid: false,
                clearable: true,
                required: true,
                readonly: false,
                errorKey: '',
                class: 'sm'
            },        
            last_name: {
                value: '',
                isValid: false,
                clearable: true,
                required: true,
                readonly: false,
                errorKey: '',
                class: 'sm'
            },
            role: {
                value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
                isValid: false, // Валидно ли поле
                clearable: true, // Показывает значок очистки
                required: true, // Обязательность
                readonly: false, // Редактируемость
                isMultiselect: false, // Возможность множественного выбора 
                isTags: false, // Показывать в виде тегов
                isLiveSearch: true, // Показывать возможность фильтрации по строке
                dataList: [],// Данные для выпадающего списка { id: 1, name: 'id 1 text text text' }
                class: 'sm'
            },

            reported_to: {
                value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
                isValid: false, // Валидно ли поле
                clearable: true, // Показывает значок очистки
                required: true, // Обязательность
                readonly: false, // Редактируемость
                isMultiselect: false, // Возможность множественного выбора 
                isTags: false, // Показывать в виде тегов
                isLiveSearch: true, // Показывать возможность фильтрации по строке
                dataList: [],// Данные для выпадающего списка { id: 1, name: 'id 1 text text text' }
                class: 'sm'
            },
        }
    },

      created() {
        this.fetchRoles()
        this.fetchUsers()

        for (let key in this.rowData) {
            const element = this.rowData[key]
            if(key in this.$data) {
                if(element && typeof element === 'object' && element.length)  this.$data[key].value = element
                else if(key === 'role' || key === 'reported_to') this.$data[key].value = [element]
                else this.$data[key].value = element

                if(element || element === 0 || element === false) this.$data[key].isValid = true  
            }
        }
    },


    computed: {
        isValid() {
            if(!this.email.isValid ||
            !this.first_name.isValid ||
            !this.last_name.isValid ||
            !this.role.isValid)
            return false

            return true
        }
    },

    methods: {
        async fetchRoles(){
            let result = await fetchData( urls.roles, 'GET', null, 'page=1&per_page=all&role=asc') 
            if(!result?.detail) { 
               this.role.dataList = (result?.rows || []).map(item => {
                    return {
                        id: item.role,
                        name: item.role
                    }
                })
            }
            else {
                console.error(result.detail)
            }
        },

        async fetchUsers(){
            let result = await fetchData( urls.users, 'GET', null, 'page=1&per_page=all&email=asc') 
            if(!result?.detail) { 
               this.reported_to.dataList = (result?.rows || []).map(item => {

                    return {
                        id: item.email,
                        name: item.email
                    }
                })

                this.reported_to.dataList = this.reported_to.dataList.filter(item => item.name !== this.rowData.email)
            }
            else {
                console.error(result.detail)
            }
        },

        cancel() {
            this.$emit('cancel')
        },

        save() {
            if(!this.isValid) return

            this.$emit('save', this.rowForSave())
        },
    }
}
</script>